import styled from 'styled-components'

const StyledPageCdv = styled.section`
    width: 1296px;
    height: auto;
    display: flex;
    flex-direction: column;

        @media screen and (max-width: 1296px) {
            width: 100% !important;
            padding: 0 3vw !important;

                h3 {
                    font-size: 22px !important;
                }
                small {
                    font-size: 19px !important;
                }
        }
        @media screen and (max-width: 620px) {
            .container-sarl {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .container-id .container-sarl .wrapper-img {
                margin-bottom: 24px;
                width: 170px !important;
            }
            .container-id .container-sarl .wrapper-txt {
                padding-left: 0 !important;
            }
            h3 {
                display: flex;
                flex-direction: column;
            }
        }

        h3 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding: 24px 0 48px 0;
        }

        small {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
        }

        .container-id {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .container-id .container-sarl {
            width: 100%;
            display: flex;
            height: auto;
            margin-bottom: 48px;
        }

        .container-id .container-sarl .wrapper-img {
            width: 150px;
            height: auto;
        }
        .container-id .container-sarl .wrapper-img img {
            width: 100%;
        }

        .container-id .container-sarl .wrapper-txt {
            display: flex;
            height: auto;
            padding-left: 72px;
            flex-direction: column;
        }
        .container-id .container-sarl .wrapper-txt span {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 21px;
        }
        .container-id .container-sarl .wrapper-txt h4{
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            padding-bottom: 24px;
            color: #000000;
        }
        .container-id {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
        }
`

export default StyledPageCdv