import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import StyledPageCdv from './StyledPageCdv';
import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import { ConditionItem } from '../ConditionItem/ConditionItem';
import Logo from '../Logo.svg'
import logoVitaFons from '../../../static/logoVitaFons.png'

export const PageCdv = () => {
    const { onglets } = useStaticQuery(
        graphql`
            query {
                onglets:datoCmsConditionDeVente {
                        onglet1
                        onglet2
                        onglet3
                        onglet4Node {
                          childMarkdownRemark {
                            html
                          }
                        }
                        onglet5
                        onglet6
                        onglet7
                        onglet8
                        onglet9
                        onglet10Node {
                          childMarkdownRemark {
                            html
                          }
                        }
                    }
            }
        `)
    const { titlePage } = useContext(GlobalStateContext)
    const infosFontVie = [
        'Raison sociale : FONT~VIE SARL',
        'Statut juridique : SARL',
        'Capital social : Capital fixe : 9.909,19 €',
        ' Siège social : Sous la Haye',
        ' Code postal : 35540',
        ' Ville : PLERGUER',
        ' Pays : France',
        ' Téléphone : 02 99 58 12 94',
        ' E-mail : font.vie@gmail.com',
        ' Site web de vente en ligne : https://www.fontvie.com',
        ' Horaires d’ouverture : 9h30 à 13h - 14h à 17h30 (du lundi au vendredi)',
        ' TVA intra-communautaire : FR 00 413 451 923',
        ' N° SIREN : 413 451 923',
        ' N° SIRET : 413 451 923 00010',
        ' Code APE/NA : 4791 B',
    ]

    const infosVitaFons = [
        'Vita Fons II Limited',
        'Combe Castle, Elworthy',
        'Taunton, Somerset',
        'England TA4 3PX',
        'Telephone : 0044 (0)1984 656245',
        'Fax : 0044 (0)1984 656734',
        'Web site : http://www.vitafons.com/index.html',
        'E-mail : vitafons@btconnect.com',
    ]
    const conditionsItems = [
        {
            title: 'Acceptation des conditions',
            desc: onglets.onglet1,
        },
        {
            title: 'Produits',
            desc: onglets.onglet2,
        },
        {
            title: 'Commande',
            desc: onglets.onglet3,
        },
        {
            title: 'Livraison',
            desc: onglets.onglet4Node.childMarkdownRemark.html,
        },
        {
            title: 'Rétractation',
            desc: onglets.onglet5,
        },
        {
            title: 'Prix et TVA',
            desc: onglets.onglet6,
        },
        {
            title: 'Paiement',
            desc: onglets.onglet7,
        },
        {
            title: 'Litiges',
            desc: onglets.onglet8,
        },
        {
            title: 'Garantie',
            desc: onglets.onglet9,
        },
        {
            title: 'Confidentialité et protection des données personnelles',
            desc: onglets.onglet10Node.childMarkdownRemark.html,
        },
    ]

    return (
        <StyledPageCdv>
            <div className='wrapper-titleh3'>
                <h3>{titlePage} / <small>FONT VIE SARL : Identification du vendeur et conditions générales de vente</small></h3>
            </div>
            <div className='container-id'>
                <div className='container-sarl'>
                    <div className='wrapper-img'>
                        <img alt='logo' src={Logo} /> 
                    </div>
                    <div className='wrapper-txt'>
                        <h4>Identification du vendeur :</h4>
                        {infosFontVie.map((info,id) => {
                            return (
                                <span key={id}>{info}</span>
                            )
                        })}
                    </div>
                </div>
                <div className='container-sarl'>
                    <div className='wrapper-img'> 
                        <img alt='logo' src={logoVitaFons} />
                    </div>
                    <div className='wrapper-txt'>
                        <h4>FONT~VIE SARL est l'importateur officiel pour la France des produits fabriqués par :</h4>
                        {infosVitaFons.map((info,id) => {

                            return (
                                <span key={id}>{info}</span>
                            )
                        })}
                    </div>
                </div>
                <p>
                Ce site est la propriété de la SARL Font~Vie.<br/>
                La marque VITA FONS II® est la propriété de Vita Fons II Limited.<br/>
                Toute reproduction, intégrale ou partielle, est systématiquement soumise à l'autorisation des propriétaires. Toutefois, les liaisons du type hypertextes vers le site sont autorisées sans demandes spécifiques.
                </p>
            </div>
                {conditionsItems.map((item,id) => {
                    return (
                        <ConditionItem key={id} id={id + 1} title={item.title} desc={item.desc} />
                    )
                })}
        </StyledPageCdv>
    )
}
